<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl" :src="barImage" mobile-break-point="960" app width="260" v-bind="$attrs">
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img :src="require('@/assets/icon.png')" max-height="30" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1">Incube MD</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group  v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>


  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-handshake',
        title: 'Solutions',
        to: '/Dashboard/Solutions',
      },
      {
        title: 'Products',
        icon: 'mdi-printer',
        to: '/tables/MainItem',
      },
      {
        title: 'NewsLetter',
        icon: 'mdi-newspaper',
        to: '/components/NewsLetter',
      },
      {
        title: 'Academy',
        icon: 'mdi-school ',
        to: '/components/Academy',
      },
      {
        title: 'IIC',
        icon: 'mdi-school ',
        to: '/components/IIC',
      },
      {
        title: 'Partner',
        icon: 'mdi-account-multiple',
        to: '/components/Partner',
      },
      {
        title: 'Portal',
        icon: 'mdi-folder-network',
        to: '/components/Portal',
      },
      {
        title: 'Careers',
        icon: 'mdi-bag-checked',
        to: '/components/Career',
      },
      {
        title: 'Events',
        icon: 'mdi-calendar-clock',
        to: '/components/Events',
      },
      {
        title: 'Settings',
        icon: 'mdi-cog-box',
        to: '/components/Settings',
      },
      {
        title: 'Defines',
        icon: 'mdi-book-plus',
        to: '/components/Defines',
      },
    ],
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      if (this.$store.state.userType == 'hr')
        return this.items.filter(function(item){ return  item.title == 'Careers'; }).map(this.mapItem)
      else
        return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },

  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
  },
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
